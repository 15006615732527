<template>
  <section class="l-content">
    <el-card>
      <el-form :inline="true" :model="condition">
        <el-form-item label="所属企业">
          <l-select v-model="condition.enterpriseCode" :remote="$api.enterpriseList" @change="getPageData"></l-select>
        </el-form-item>
        <el-form-item label="建议类型">
          <l-select v-model="condition.proposalType" :remote="$api.proposalType"></l-select>
        </el-form-item>
        <el-form-item label="建议分类">
          <l-select v-model="condition.proposalCategory" :data="$constant.proposalCategoryList"></l-select>
        </el-form-item>
        <el-form-item label="建议状态">
          <l-select v-model="condition.proposalStatus" :data="$constant.proposalStatusList" @change="getPageData"></l-select>
        </el-form-item>
        <el-form-item>
          <template slot="label">
            <l-select v-model="condition.dateType" :all="false" :data="$constant.proposal_dateType"></l-select>
          </template>
          <l-datepicker type="daterange" :default="[condition.startDate, condition.endDate]" @change="dataRangeChange"></l-datepicker>
        </el-form-item>
        <el-form-item>
          <el-input v-model="condition.keyword" placeholder="建议标题/员工名称/建议内容" style="width:300px;"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getPageData()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider> </el-divider>
    <el-card v-if="pageData">
      <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex">
        <template slot="enterpriseName" slot-scope="scope">
          <el-button type="text" @click="$site.view_enterprise_detail(scope.row.enterpriseCode)">{{ scope.row.enterpriseName }}</el-button>
        </template>
        <template slot="proposalTitle" slot-scope="scope">
          <el-button type="text" @click="$site.view_proposal_detail(scope.row.proposalId)">{{ scope.row.proposalTitle }}</el-button>
        </template>
        <template slot="proposalStatus" slot-scope="scope">
          <span v-if="scope.row.proposalStatus == 0" class="text-grey">未提交</span>
          <span v-else-if="scope.row.proposalStatus == 1" class="text-blue">已提交</span>
          <span v-else-if="scope.row.proposalStatus == 2" class="text-mauve">已入围</span>
          <span v-else-if="scope.row.proposalStatus == 3" class="text-green">已采纳</span>
          <span v-else-if="scope.row.proposalStatus == -1" class="text-red">无效建议</span>
          <span v-else-if="scope.row.proposalStatus == -2" class="text-red">无法实施</span>
        </template>
      </l-table>
      <l-pager :page="pageData" @change="getPageData"></l-pager>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'ProposalList',
  data() {
    return {
      tableOption: {
        stripe: true,
        height: document.documentElement.clientHeight - 280 - 50 + 'px',
        columns: [
          { label: '序号', type: 'index' },
          { label: '所属企业', slot: 'enterpriseName' },
          { label: '建议标题', slot: 'proposalTitle' },
          { label: '建议类型', prop: 'proposalType' },
          { label: '建议分类', prop: 'proposalCategory' },
          { label: '所属部门', prop: 'createDepartName' },
          { label: '建议日期', prop: 'proposalTime', filter: (a, data) => this.$lgh.limitTo(a, 10) },
          { label: '建议人', prop: 'createUserName' },
          { label: '状态', slot: 'proposalStatus' }
        ]
      },
      condition: {
        enterpriseCode: '',
        dateType: 'proposalTime',
        startDate: new Date().getFullYear() + '-01-01',
        endDate: this.$lgh.getDate(),
        keyword: '',
        proposalStatus: '',
        proposalCategory: '',
        proposalType: '',
        pageIndex: 1,
        pageSize: 10
      },
      pageData: null
    }
  },
  created() {
    this.getPageData()
  },
  methods: {
    dataRangeChange(d1, d2) {
      this.condition.startDate = d1
      this.condition.endDate = d2
    },
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/proposal/pagedlist', ['enterpriseCode', this.condition])
    }
  }
}
</script>
